<script>
import PollNew from './PollNewViewVlm'

export default {
  name: 'PollEditViewVlm',
  extends: PollNew,
  created () {
    this.getPoll()
  }
}
</script>
